<template>
	<div class="Auth mx-auto">
		<v-card
			max-width="400"
			class="d-flex align-start flex-column mx-auto"
			elevation="0"
		>
			<v-card-title class="px-0 pt-0">
				<h1 class="heading">휴대폰 번호 인증</h1>
			</v-card-title>
			<v-card-subtitle class="pl-0 text-base">
				사용하시는 휴대폰 번호를 인증하신 후,
				<br />
				서비스 이용이 가능합니다
			</v-card-subtitle>
			<div>
				<validation-observer ref="observer" v-slot="{ valid }">
					<v-row no-gutters>
						<v-col
							cols="12"
							md="12"
							sm="12"
							:class="isMdAndUp ? 'pr-1' : 'pr-0'"
						>
							<v-select
								v-model="countryCode"
								:items="countryCodes"
								item-text="name"
								item-value="value"
							/>
						</v-col>
						<v-col md="9" sm="9" class="pr-1">
							<validation-provider
								v-slot="{ errors }"
								name="phone"
								rules="required|phone"
							>
								<v-text-field
									v-model="phoneNumber"
									:error-messages="errors"
									@keypress="allowOnlyNumber($event)"
									label="휴대폰 번호"
								/>
							</validation-provider>
						</v-col>
						<v-col md="3" sm="3" class="pt-2">
							<v-btn
								@click="onCertification"
								:disabled="(phoneNumber ? false : true) || !valid"
								color="primary"
								class="verification-btn"
							>
								인증요청
							</v-btn>
						</v-col>
					</v-row>
				</validation-observer>
			</div>
		</v-card>
	</div>
</template>

<script>
import { ref, reactive, computed, onMounted } from '@vue/composition-api'

import store from '@/store'
import { useRouter, getVuetify } from '@core/utils'
import { successSwal, warningSwal } from '@/plugins/swalMixin'
import { krPhoneFormat, internationalPhoneFormat } from '@/filter'

import AuthService from '@/services/AuthService'

export default {
	setup() {
		const $vuetify = getVuetify()
		const { router } = useRouter()
		const profile = computed(() => store.getters['user/getMeDetail'])
		const countryCode = ref('82')
		const countryCodes = reactive([
			{
				name: '대한민국+82',
				value: '82',
			},
		])
		const phoneNumber = ref(
			profile.value?.phoneNumber
				? krPhoneFormat(profile.value?.phoneNumber)
				: '',
		)
		const allowOnlyNumber = $event => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}
		}

		const updateUserCertification = async imp_uid => {
			try {
				const payload = {
					iamportUid: imp_uid,
					phoneNumber: internationalPhoneFormat(phoneNumber.value),
				}
				await AuthService.updateUserCertification(payload)
				await AuthService.init()

				const confirm = await successSwal({
					html: '본인인증이 완료되었습니다',
					allowOutsideClick: false,
				})
				if (confirm.isConfirmed) {
					router.push({ name: 'mypage-dashboard' })
				}
			} catch (e) {
				warningSwal(
					e.response.status === 400
						? e.response.data.message
						: '인증정보를 불러오는데 문제가 발생되었습니다',
				)
			}
		}

		const callback = async response => {
			/* 3. 콜백 함수 정의하기 */
			const { success, imp_uid, error_msg } = response

			if (success) {
				updateUserCertification(imp_uid)
			} else {
				warningSwal(error_msg)
			}
		}

		const isMobile = () => {
			const device = navigator.userAgent
			if (
				/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
					device,
				)
			) {
				return true
			}
		}

		const onCertification = () => {
			/* 1. 가맹점 식별하기 */
			const { IMP } = window
			IMP.init(process.env.VUE_APP_MERCHANT_ID_CODE)

			/* 2. 본인인증 데이터 정의하기 */
			const data = {
				merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
				name: '',
				phone: phoneNumber.value,
				company: 'https://itmarketer.org/',
				popup: isMobile() ? true : false,
			}

			/* 4. 본인인증 창 호출하기 */
			IMP.certification(data, callback)
		}

		store.dispatch('app/setIsLogoWhite', false)

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		onMounted(() => {
			if (profile.value && profile.value?.ciVerified) {
				router.push({ name: 'mypage-dashboard' })
			}
		})

		return {
			isMdAndUp,
			phoneNumber,
			countryCode,
			countryCodes,

			allowOnlyNumber,
			onCertification,
		}
	},
}
</script>

<style lang="scss" scoped>
.container {
	height: 100vh;
	background-color: white;
}

.full-width {
	width: 100%;
	max-width: 100%;
}

.Auth {
	height: 100vh;
	margin-top: 10em;

	form {
		justify-content: center;
		text-align: center;

		.v-input {
			max-width: 400px;
			margin: 0 auto;
		}
	}

	.v-card {
		padding-top: 44px;
		padding-left: 34px;
		padding-right: 34px;
		padding-bottom: 34px;
		border: 1px solid #e4e5ed;
		width: 100%;

		@media screen and (max-width: 768px) {
			padding: 22px;
		}

		.signup-form {
			width: 100%;
		}
	}

	h1.heading {
		font-size: 25px;
		font-weight: normal;
		color: #303441;
		line-height: normal;
		margin-top: 0;
	}

	.span-text {
		color: #888;
		font-size: 14px !important;
		font-weight: 500 !important;
		cursor: pointer;
	}

	button {
		width: 100%;
		min-width: 100% !important;
		min-height: 40px;
		font-size: 14px !important;
	}
}
</style>
